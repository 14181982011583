import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AnchorNavigationSpot } from '@ncg/data';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-anchor-navigation-spot',
    template: `
        <div
            class="anchor-navigation"
            [ngClass]="{
                'anchor-navigation--align-left': data.alignment === 'Left',
                'anchor-navigation--scroll-both': !isMaxLeft && !isMaxRight,
                'anchor-navigation--scroll-right': isMaxLeft,
                'anchor-navigation--scroll-left': isMaxRight
            }"
            ncgLoadIn
        >
            <ul #anchorList class="anchor-navigation__list" (scroll)="handleScroll()">
                <li *ngFor="let link of data.anchorItems" class="anchor-navigation__item">
                    <a
                        class="anchor-navigation__item-link"
                        [href]="link.url"
                        [title]="link.linkHoverTitle"
                        [attr.aria-label]="link.linkHoverTitle"
                        ncgInterceptLinks
                    >
                        <span class="anchor-navigation__item-link-icon"></span>
                        <span class="anchor-navigation__item-link-title">{{ link.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorNavigationSpotComponent extends SpotBaseDirective implements AfterViewInit {
    static ref = 'anchornavigation';

    public isMaxLeft = false;
    public isMaxRight = false;

    @ViewChild('anchorList')
    public anchorList?: ElementRef;
    @Input()
    public data: AnchorNavigationSpot;

    public ngAfterViewInit() {
        this.handleScroll();
    }

    public handleScroll(): void {
        if (!this.anchorList || !this.anchorList.nativeElement) {
            return;
        }

        const scrollLeft = this.anchorList?.nativeElement.scrollLeft;
        const maxScrollLeft = this.anchorList?.nativeElement.scrollWidth - this.anchorList?.nativeElement.clientWidth;

        this.isMaxLeft = scrollLeft === 0;
        this.isMaxRight = Math.round(scrollLeft) >= maxScrollLeft;
    }
}

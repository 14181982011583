import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MapSpot } from '@ncg/data';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-map-spot',
    template: '<ng-container #vc></ng-container>',
})
export class MapSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'map';
    @ViewChild('vc', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;
    @Input() data: MapSpot;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly featureDetectionService: FeatureDetectionService
    ) {
        super();
    }

    async ngOnInit() {
        if (this.featureDetectionService.isBrowser()) {
            this.viewContainer.clear();
            const { MapContainerComponent } = await import('./map-container.component');
            const ref = this.viewContainer.createComponent(MapContainerComponent);
            ref.instance.data = this.data;
            this.cd.markForCheck();
        }
    }
}

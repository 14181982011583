import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { ScriptLoadService } from './script-load.service';
import { SettingsService } from './settings.service';
import { TrackingService } from './tracking.service';

@Injectable({
    providedIn: 'root',
})
export class BloomreachService implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly scriptLoad: ScriptLoadService,
        private readonly settings: SettingsService,
        private readonly tracking: TrackingService,
        @Inject(DOCUMENT) private readonly doc: Document,
        @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public async initBloomreach() {
        const isServer = isPlatformServer(this.platformId);

        // TODO Revert this later. Temporary fix to check for consent before loading bloomreach. Script should load on server.
        if (!isServer) {
            const { bloomreachToken } = await firstValueFrom(this.settings.get());
            if (!bloomreachToken) {
                return;
            }

            this.tracking
                .marketingConsentGiven()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((hasConsent) => {
                    if (hasConsent) {
                        // Add token to html for script.
                        this.scriptLoad.loadScript(
                            undefined,
                            {
                                textContent: `globalThis.NIC = { bloomreach: { token: '${bloomreachToken}' } };`,
                            },
                            false
                        );

                        // Add script to html.
                        this.scriptLoad.loadScript('/assets/scripts/bloomreach-async.js', { async: true }, false);

                        // Next unsubscribe to avoid loading script multiple times.
                        this.unsubscribe.next();
                    }
                });
        }
    }
}

import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TabbedContentSpot } from '@ncg/data';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { Subject } from 'rxjs';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-tabbed-content-spot',
    styleUrls: ['./tabbed-content-spot.component.scss'],
    template: `
        <article class="tabbed-content">
            <h1 class="tabbed-content__heading" *ngIf="data.heading">{{ data.heading }}</h1>
            <ncg-rich-text class="tabbed-content__subheading" *ngIf="data.subheading" [html]="data.subheading"></ncg-rich-text>
            <ng-container *ngIf="data.tabs?.length">
                <div class="tabbed-content__tabs" role="tablist" aria-label="tabs">
                    <button
                        type="button"
                        class="tabbed-content__tab button"
                        *ngFor="let tab of data.tabs; let tabIndex = index"
                        [class.tabbed-content__tab--active]="tabIndex === activeIndex"
                        [class.tabbed-content__fade]="tab.fadeIfInactive"
                        [class.tabbed-content__indicator]="tab.showActiveIndicator"
                        [ngClass]="tab.buttonStyle"
                        [title]="tab.buttonText"
                        (click)="onTabClick(tabIndex)"
                        role="tab"
                        [id]="'tabbed-content__tab-' + tabIndex"
                        [attr.aria-selected]="tabIndex === activeIndex"
                        [attr.aria-controls]="'tabbed-content__panel-' + tabIndex"
                        [tabindex]="tabIndex"
                    >
                        {{ tab.buttonText }}
                    </button>
                </div>
                <ng-container *ngFor="let panel of data.tabs; let panelIndex = index">
                    <article
                        *ngIf="panelIndex === activeIndex"
                        [id]="'tabbed-content__panel-' + panelIndex"
                        role="tabpanel"
                        tabindex="0"
                        [attr.aria-labelledby]="'tabbed-content__tab-' + panelIndex"
                    >
                        <section class="section" *ngFor="let tabSpot of panel.tabContent ?? []">
                            <ng-template
                                ncgDynamicSpotComponent
                                [spotType]="tabSpot.alias"
                                [spotData]="tabSpot"
                                [allowNarrowContent]="allowNarrowContent"
                                [isFirstSpot]="false"
                            ></ng-template>
                        </section>
                    </article>
                </ng-container>
            </ng-container>
        </article>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabbedContentSpotComponent extends SpotBaseDirective implements OnInit, OnDestroy {
    static ref = 'tabbedcontent';

    private readonly unsubscribe = new Subject<void>();
    private readonly hashParam = '#tab-id=';

    public activeIndex?: number;

    @Input()
    public data: TabbedContentSpot;

    constructor(
        private readonly location: Location,
        private readonly cd: ChangeDetectorRef,
        private readonly featureDetection: FeatureDetectionService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public ngOnInit(): void {
        this.setActiveIndex(this.location.path(true));
        if (this.featureDetection.isBrowser()) {
            this.location.onUrlChange((url) => {
                this.setActiveIndex(url);
            });
        }
    }

    public onTabClick(index: number): void {
        this.location.replaceState(this.location.path(false) + this.hashParam + String(index));
    }

    private setActiveIndex(url: string) {
        this.activeIndex = Number(url.split('#')[1]?.split('=')[1]) || 0;
        this.cd.markForCheck();
    }
}

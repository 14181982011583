import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { ModelPageNavigationSpot, NcgAppComponentInterface } from '@ncg/data';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FeatureDetectionService } from '../../core/feature-detection.service';
import { HeaderService } from '../../core/header.service';

import { ScrollService } from '../../core/scroll.service';
import { SidePanelService } from '../../side-panel/side-panel.service';
import { SpotBaseDirective } from '../spot-base.class';
import { ModelPageNavigationSpotService } from './model-page-navigation-spot.service';
import { ScrollStatusService } from '../../core/scroll-status.service';

@Component({
    selector: 'ncg-model-page-navigation-spot',
    template: `
        <!--Root version (no load-in animation)-->
        <div
            *ngIf="isRootVersion"
            class="model-page-navigation-spot__wrapper model-page-navigation-spot__wrapper--sticky"
            [ngClass]="{
                'model-page-navigation-spot__wrapper--sticky-active': showSticky
            }"
            [style.transform]="'translateY(' + translateBy + 'px)'"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        <!--Regular version (with load-in animation)-->
        <div *ngIf="!isRootVersion" class="model-page-navigation-spot__wrapper" ncgLoadIn>
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        <!--Content-->
        <ng-template #content>
            <div class="container is-fullwidth">
                <nav
                    #nav
                    (scroll)="handleScroll()"
                    class="model-page-navigation-spot__navbar"
                    [class.model-page-navigation-spot__navbar--inverted]="isRootVersion"
                >
                    <a
                        class="model-page-navigation-spot__navbar-item"
                        [class.model-page-navigation-spot__navbar-item--inverted]="isRootVersion"
                        [routerLinkActiveOptions]="{ exact: true }"
                        [routerLinkActive]="'model-page-navigation-spot__navbar-item--active'"
                        [routerLink]="[data.modelParentUrl]"
                        >{{ 'models.model_overview' | translate }}</a
                    >
                    <a
                        *ngFor="let link of data.items"
                        class="model-page-navigation-spot__navbar-item"
                        [class.model-page-navigation-spot__navbar-item--inverted]="isRootVersion"
                        [routerLinkActive]="'model-page-navigation-spot__navbar-item--active'"
                        [routerLink]="[link.url]"
                        >{{ link.name }}</a
                    >
                    <a
                        *ngIf="data.allowTestDrive"
                        class="model-page-navigation-spot__navbar-item"
                        [class.model-page-navigation-spot__navbar-item--inverted]="isRootVersion"
                        (click)="openTestDrive()"
                        >{{ 'models.book_test_drive' | translate }}</a
                    >
                    <a
                        *ngIf="data.allowSendOffer"
                        class="model-page-navigation-spot__navbar-item"
                        [class.model-page-navigation-spot__navbar-item--inverted]="isRootVersion"
                        (click)="openOffer()"
                        >{{ 'models.send_offer' | translate }}</a
                    >
                </nav>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModelPageNavigationSpotComponent extends SpotBaseDirective implements AfterViewInit, OnDestroy {
    static ref = 'modelpagenavigation';

    private readonly unsubscribe = new Subject<void>();
    private roBody?: ResizeObserver;

    private ioDown?: IntersectionObserver;
    private ioUp?: IntersectionObserver;
    private rootVersionRef?: ComponentRef<ModelPageNavigationSpotComponent>;

    @Input()
    public data: ModelPageNavigationSpot;

    @ViewChild('nav')
    public navRef?: ElementRef<HTMLElement>;
    public isRootVersion: boolean = false;
    public showSticky: boolean = false;
    public translateBy: number = 0;
    private currentDirection: 'up' | 'down' = 'up';
    private headerHeight: number = 0;

    constructor(
        private readonly element: ElementRef,
        private readonly appRef: ApplicationRef,
        private readonly cd: ChangeDetectorRef,
        private readonly scrollService: ScrollService,
        private readonly sidePanelService: SidePanelService,
        private readonly featureService: FeatureDetectionService,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        private readonly modelPageNavigationSpotService: ModelPageNavigationSpotService
    ) {
        super();
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.destroyObservers();
        if (this.rootVersionRef) {
            this.rootVersionRef.destroy();
        }
    }

    public ngAfterViewInit() {
        if (this.featureService.isBrowser()) {
            this.scrollToActiveLink();
            this.handleScroll();
            setTimeout(() => {
                if (!this.isRootVersion) {
                    this.createHeaderListeners();
                    this.modelPageNavigationSpotService.rootScroll$
                        .pipe(distinctUntilChanged(), debounceTime(50), takeUntil(this.unsubscribe))
                        .subscribe((val) => {
                            this.setScroll(val);
                        });
                } else {
                    this.modelPageNavigationSpotService.childScroll$
                        .pipe(distinctUntilChanged(), debounceTime(50), takeUntil(this.unsubscribe))
                        .subscribe((val) => {
                            this.setScroll(val);
                        });
                }
            });
        }
    }

    public toggleStickyState(isActive: boolean) {
        this.showSticky = isActive;
        this.cd.markForCheck();
    }

    public setTranslateBy(position: number) {
        this.translateBy = position;
        this.cd.markForCheck();
    }

    public handleScroll(): void {
        if (!this.navRef || !this.navRef.nativeElement) {
            return;
        }

        const { scrollLeft } = this.navRef.nativeElement;

        if (!this.isRootVersion) {
            this.modelPageNavigationSpotService.childScroll$.next(scrollLeft);
        } else {
            this.modelPageNavigationSpotService.rootScroll$.next(scrollLeft);
        }
    }

    public openTestDrive(): void {
        if (!this.data.vehicleLms) {
            return;
        }

        this.sidePanelService.openTestDrive({
            isModelPage: true,
            collectMarketingConsent: this.data.testDriveCollectMarketingConsent,
            locationWhitelist: this.data.locationWhitelist,
            locationBlacklist: this.data.locationBlacklist,
            models: [
                {
                    name: this.data.modelParentName || '',
                    url: this.data.modelParentUrl || '',
                    pimId: this.data.pimModelId || '',
                    image: this.data.image,
                    vehicleModel: this.data.vehicleLms,
                },
            ],
        });
    }

    public openOffer(): void {
        if (!this.data.vehicleLms) {
            return;
        }

        this.sidePanelService.openOffer({
            id: this.data.vehicleLms,
            isCashAllowed: this.data.isCashAllowed,
            isLoanAllowed: this.data.isLoanAllowed,
            isLeasingAllowed: this.data.isLeasingAllowed,
            isUsedCar: false,
            name: this.data.modelParentName || '',
            subtitle: '',
            image: this.data.image,
        });
    }

    private setScroll(val: number) {
        const elm = this.navRef?.nativeElement;
        if (elm) {
            elm.scrollLeft = val;
        }
    }

    private scrollToActiveLink() {
        setTimeout(() => {
            if (this.navRef && this.navRef.nativeElement) {
                const navElement: HTMLElement = this.navRef.nativeElement;
                const activeLink: HTMLLinkElement | null = navElement.querySelector('.is-active');
                if (activeLink) {
                    let scrollNumber;
                    const btnPadding = 20;
                    const navWidth = navElement.offsetWidth;
                    const navOffsetLeft = navElement.scrollLeft;
                    const activeLinkLeft: number = activeLink.offsetLeft;
                    const activeLinkWidth: number = activeLink.offsetWidth;

                    if (activeLinkWidth + activeLinkLeft > navWidth + navOffsetLeft) {
                        scrollNumber = activeLinkWidth + activeLinkLeft - navWidth;
                        scrollNumber = scrollNumber + btnPadding;
                    } else if (navOffsetLeft > activeLinkLeft) {
                        scrollNumber = activeLinkLeft;
                        scrollNumber = scrollNumber - btnPadding;
                    } else {
                        return;
                    }

                    this.scrollService.scrollToPosition({ left: scrollNumber, behavior: 'auto' }, navElement);
                }
            }
        });
    }

    private createHeaderListeners() {
        const rootViewRef = (this.appRef.components[0].instance as NcgAppComponentInterface).viewContainerModelPageNav;
        if (rootViewRef) {
            this.rootVersionRef = rootViewRef.createComponent(ModelPageNavigationSpotComponent, { index: 0 });
            this.rootVersionRef.instance.data = this.data;
            this.rootVersionRef.instance.isRootVersion = true;
            this.cd.markForCheck();
        }

        let prevPosition: number = 0;
        combineLatest([this.headerService.getHeaderEvents(), this.scrollStatusService.getScrollPosition()])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(([headerHeight, scrollPosition]) => {
                if (scrollPosition >= prevPosition) {
                    if (this.currentDirection === 'up') {
                        this.rootVersionRef?.instance.setTranslateBy(0);
                        this.currentDirection = 'down';
                    }
                } else {
                    if (this.currentDirection === 'down') {
                        // Check for overlays and similar disabling scroll.
                        const pageIsFixedPosition =
                            scrollPosition === 0 &&
                            (window.getComputedStyle(document.documentElement).position === 'fixed' ||
                                window.getComputedStyle(document.body).position === 'fixed');

                        this.rootVersionRef?.instance.setTranslateBy(!pageIsFixedPosition ? headerHeight : 0);
                        this.currentDirection = 'up';
                    } else if (scrollPosition === 0) {
                        const pageIsFixedPosition =
                            window.getComputedStyle(document.documentElement).position === 'fixed' ||
                            window.getComputedStyle(document.body).position === 'fixed';
                        if (pageIsFixedPosition) {
                            this.rootVersionRef?.instance.setTranslateBy(0);
                        }
                    }
                }
                prevPosition = scrollPosition;
                const headerHeightChanged = this.headerHeight !== headerHeight;
                if (headerHeightChanged) {
                    this.headerHeight = headerHeight;
                    this.createObservers();
                }
            });
    }
    private destroyObservers() {
        this.roBody?.disconnect();
        this.ioUp?.disconnect();
        this.ioDown?.disconnect();
    }

    private createObservers(): void {
        this.destroyObservers();
        const element: HTMLElement = this.element.nativeElement;
        let clientRect: DOMRect = element.getBoundingClientRect();

        this.roBody = new ResizeObserver(() => {
            clientRect = element.getBoundingClientRect();
        });
        this.roBody.observe(document.body);

        this.ioUp = new IntersectionObserver(
            (entries) => {
                if (this.rootVersionRef) {
                    if (this.currentDirection === 'up') {
                        this.rootVersionRef.instance.toggleStickyState(!(entries[0].isIntersecting || clientRect.top > window.scrollY));
                    }
                }
            },
            {
                rootMargin: `-${clientRect.height + this.headerHeight}px 0px 0px 0px`,
            }
        );
        this.ioUp.observe(element);

        this.ioDown = new IntersectionObserver(
            (entries) => {
                if (this.rootVersionRef) {
                    if (this.currentDirection === 'down') {
                        this.rootVersionRef.instance.toggleStickyState(!(entries[0].isIntersecting || clientRect.top > window.scrollY));
                    }
                }
            },
            {
                rootMargin: `-${clientRect.height}px 0px 0px 0px`,
            }
        );
        this.ioDown.observe(element);
    }
}

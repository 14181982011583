import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NcgAddress, NcgLocation } from '@ncg/data';

@Component({
    selector: 'ncg-dealer-info',
    template: `
        <div class="dealer-info">
            <div class="columns" [ngClass]="{ 'has-text-centered-mobile': isCenteredMobile }">
                <div class="column">
                    <address *ngIf="address">
                        <label class="dealer-info__label">{{ 'map.address_label' | translate }}</label>
                        <p>
                            {{ address.street }}<br />
                            {{ address.zipcode }} {{ address.city }}
                        </p>
                    </address>

                    <ng-container *ngIf="phone">
                        <label class="dealer-info__label">{{ 'map.phone_label' | translate }}</label>
                        <p>
                            <a [attr.href]="'tel:' + phone">{{ phone }}</a>
                        </p>
                    </ng-container>
                </div>
                <div class="column" *ngIf="location?.opening_hours?.length">
                    <ng-container
                        *ngTemplateOutlet="
                            hoursTmpl;
                            context: { title: 'map.opening_hours_headline' | translate, openingHours: location?.opening_hours }
                        "
                    ></ng-container>
                </div>
                <div class="column" *ngIf="workshop?.opening_hours?.length">
                    <ng-container
                        *ngTemplateOutlet="
                            hoursTmpl;
                            context: { title: 'map.workshop_opening_hours_headline' | translate, openingHours: workshop?.opening_hours }
                        "
                    ></ng-container>
                </div>
            </div>
        </div>
        <ng-template #hoursTmpl let-openingHours="openingHours" let-title="title">
            <label class="dealer-info__label">{{ title }}</label>
            <table class="dealer-info__opening-hours">
                <tr *ngFor="let day of openingHours">
                    <td [ngClass]="{ 'has-text-right-mobile dealer-info__day': isCenteredMobile }">{{ day.open_day_label }}</td>
                    <td [ngClass]="{ 'has-text-left-mobile': isCenteredMobile }">{{ day.open_time }} - {{ day.close_time }}</td>
                </tr>
            </table>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DealerInfoComponent implements OnInit {
    @Input() location?: NcgLocation;
    @Input() workshop?: NcgLocation;
    @Input() isCenteredMobile?: boolean;

    address: NcgAddress;
    phone: string | null;

    ngOnInit(): void {
        if (this.location) {
            this.address = this.location.address;
            this.phone = this.location.phone;
        } else if (this.workshop) {
            this.address = this.workshop.address;
            this.phone = this.workshop.phone;
        }
    }
}

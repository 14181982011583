import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { Directive, EventEmitter, HostListener, Inject, Input, OnDestroy, Output, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImpactOverlayRef } from '../../overlay/overlay-ref';
import { OverlayService } from '../../overlay/overlay.service';

import { SpotsConfig } from '../../spots/spots-config';
import { STATUS } from './mobile-menu';
import { MobileMenuComponent } from './mobile-menu.component';

@Directive({
    selector: '[ncgMobileMenuTrigger]',
})
export class MobileMenuTriggerDirective implements OnDestroy {
    @Output() status: EventEmitter<STATUS> = new EventEmitter();
    @Input() hasMobileHeader = true;
    @Input() mobileMenuCmp?: TemplateRef<any> | ComponentType<any>;
    isOpen = false;
    mobileOverlay: ImpactOverlayRef;

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly overlayService: OverlayService,
        private readonly overlay: Overlay,
        @Inject(SpotsConfig) private readonly spotsConfig: SpotsConfig
    ) {}

    @HostListener('click')
    toggleMenu(): void {
        if (this.isOpen) {
            this.mobileOverlay.close();
        } else {
            this.open();
        }
    }

    open(): void {
        this.status.emit(STATUS.OPENED);
        this.isOpen = true;
        this.mobileOverlay = this.overlayService.open(this.mobileMenuCmp ?? MobileMenuComponent, {
            blockScroll: true,
            panelClass: this.hasMobileHeader ? '' : ['mobile-overlay-top-space', 'is-clipped'],
            hasBackdrop: this.hasMobileHeader,
            fullHeight: true,
            positionVertical: {
                placement: 'top',
                offset: this.hasMobileHeader ? '' : this.spotsConfig.mobileOverlayOffsetTop,
            },
            positionHorizontal: {
                placement: 'left',
            },
            data: {
                hasMobileHeader: this.hasMobileHeader,
            },
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });

        this.mobileOverlay
            .afterClose()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.status.emit(STATUS.CLOSED);
                this.isOpen = false;
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

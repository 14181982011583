import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getBuildIdQuery, ILink, IMegaMenuSettings } from '@ncg/data';
import { FeatureDetectionService, HeaderService, SettingsService, STATUS, ScrollStatusService } from '@ncg/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-header',
    template: `
        <div class="header__backdrop" [ngClass]="{ 'header__backdrop--is-open': isMegaMenuOverlayVisible }"></div>
        <div class="header__wrapper" [style.height]="headerHeight + 'px'">
            <header
                #header
                class="header"
                [ngClass]="{ 'header--is-sticky': isFixed }"
                [style.transform]="hideNavBar ? 'translate3d(0, ' + -headerHeight + 'px, 0)' : ''"
            >
                <div class="header__container">
                    <div class="columns is-gapless is-mobile">
                        <!-- Mobile Menu -->
                        <div class="header__mobile-menu column is-narrow is-hidden-desktop">
                            <button
                                ncgMobileMenuTrigger
                                [hasMobileHeader]="false"
                                (status)="onMobileStatusChange($event)"
                                type="button"
                                class="button is-clean header__mobile-button"
                                aria-label="menu"
                                aria-expanded="false"
                            >
                                <ng-container *ngIf="isMobileMenuOpen">
                                    <svg-icon-sprite
                                        [src]="'close_light'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        classes=""
                                    ></svg-icon-sprite>
                                    <div class="mobile-menu__title mobile-menu__close--text">{{ 'header.close' | translate }}</div>
                                </ng-container>

                                <ng-container *ngIf="!isMobileMenuOpen">
                                    <svg-icon-sprite
                                        [src]="'nav-aligned'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        class="mobile-menu__nav-icon"
                                        classes=""
                                    ></svg-icon-sprite>

                                    <div class="mobile-menu__title mobile-menu__close--text">{{ 'header.menu' | translate }}</div>
                                </ng-container>
                            </button>
                        </div>

                        <div class="header__logo column is-narrow-desktop is-flex level">
                            <ng-container *ngIf="logoLink?.url && logoLink?.isExternal; else default">
                                <a class="logo" [href]="logoLink?.url" [target]="logoLink?.target">
                                    <img [src]="'/assets/images/terminalen-logo.svg' + buildIdQuery" width="205" height="71" alt="Terminalen logo" />
                                </a>
                            </ng-container>

                            <ng-template #default>
                                <a class="logo" [routerLink]="['/']">
                                    <img [src]="'/assets/images/terminalen-logo.svg' + buildIdQuery" width="205" height="71" alt="Terminalen logo" />
                                </a>
                            </ng-template>
                        </div>

                        <!-- Desktop Menu -->
                        <ncg-main-menu
                            (isMegaMenuVisibleSettings)="onMegaMenuChange($event)"
                            class="header__main-menu column is-hidden-touch is-flex level level-item"
                        ></ncg-main-menu>

                        <!-- Meta menu -->
                        <ncg-meta-menu class="header__meta-menu column is-narrow is-flex"></ncg-meta-menu>
                    </div>
                </div>
            </header>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('header') private readonly headerElement: ElementRef;

    isMobileMenuOpen = false;
    isMegaMenuOverlayVisible = false;
    isFixed: boolean;
    previousPosition: number;
    hideNavBar = false;
    headerHeight: number;
    currentPosition: number;
    logoLink?: ILink;
    buildIdQuery = getBuildIdQuery();

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        private readonly featureDetection: FeatureDetectionService,
        private readonly settingsService: SettingsService
    ) {}

    ngOnInit() {
        this.settingsService
            .get()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.logoLink = settings?.logoLink;
                this.cd.markForCheck();
            });

        this.scrollStatusService
            .getScrollPosition()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((position: number) => {
                this.currentPosition = position;
                this.handleHeaderPosition();
            });
    }

    ngAfterViewInit(): void {
        this.setInitialValues();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialValues() {
        if (this.featureDetection.isBrowser() && this.headerElement) {
            this.headerHeight = this.headerElement.nativeElement.offsetHeight;
            this.previousPosition = this.headerHeight;
            this.headerService.updateModelPageHeader(this.headerHeight);

            this.cd.markForCheck();
        }
    }

    handleHeaderPosition() {
        // Alway set a fixed header, when mobile nav is open.
        if (this.isMobileMenuOpen) {
            this.isFixed = true;
            this.hideNavBar = false;
            this.cd.markForCheck();
            return;
        }

        if (this.currentPosition > this.headerHeight) {
            if (this.currentPosition > this.previousPosition) {
                this.hideNavBar = true;
                this.previousPosition = this.currentPosition - 1;
            } else {
                this.isFixed = true;
                this.hideNavBar = false;
                this.previousPosition = this.currentPosition;
            }
        } else if (this.currentPosition === 0) {
            this.isFixed = false;
            this.hideNavBar = false;
            this.previousPosition = this.headerHeight;
        }

        this.cd.markForCheck();
    }

    onMobileStatusChange(status: STATUS) {
        this.isMobileMenuOpen = status === 0;
        if (this.isMobileMenuOpen) {
            this.handleHeaderPosition();
        }
    }

    onMegaMenuChange(settings: IMegaMenuSettings) {
        this.isMegaMenuOverlayVisible = settings.isVisible;
        this.cd.detectChanges();
    }
}

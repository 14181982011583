import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IConsentDto } from '@ncg/data';
import { EMPTY, lastValueFrom, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { SettingsService } from '../core/settings.service';
import { DialogService } from '../utils/dialog/dialog.service';
import { FormService } from './form.service';

@Component({
    selector: 'ncg-consent',
    template: `
        <div class="consent consent--{{ align }}" *ngIf="settingsService.get() | async as settings">
            <label class="checkbox">
                <input class="consent__input" type="checkbox" (change)="onChecked()" [(ngModel)]="isChecked" data-testid="consentCheckbox" />
                <span class="checkmark checkmark--top-aligned"></span>
                <div class="consent__text" *ngIf="settings.inlineConsent; else defaultTmpl" [innerHtml]="consentText | safe: 'html'"></div>
                <ng-template #defaultTmpl>
                    <span class="consent__text consent__text--default" (click)="open($event)">{{ consentTitle }}</span>
                </ng-template>
            </label>
        </div>
    `,
})
export class ConsentComponent implements OnInit, OnDestroy {
    @Input() parentForm: UntypedFormGroup;
    @Input() formControlName = 'consent';
    @Input() identifier?: string;
    @Input() align: 'center' | 'left' = 'center';
    consentTitle: string;
    consentText: string;
    isChecked = false;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private dialogService: DialogService,
        private formService: FormService,
        private readonly cd: ChangeDetectorRef,
        public readonly settingsService: SettingsService
    ) {}

    get control() {
        return this.parentForm.controls[this.formControlName];
    }

    ngOnInit() {
        this.formService
            .getConsent(this.identifier)
            .pipe(
                catchError(() => {
                    console.warn('Unable to fetch consent information');
                    this.consentTitle = 'Consent text is not available';
                    this.cd.markForCheck();
                    return EMPTY;
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((consent) => {
                this.consentTitle = consent.attributes.title;
                this.consentText = consent.attributes.content;
                this.cd.markForCheck();
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    open(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        lastValueFrom(this.formService.getConsent(this.identifier))
            .then((consent) => {
                if (!consent) {
                    return;
                }

                return this.dialogService.openDialog({
                    data: {
                        header: consent.attributes.title,
                        text: consent.attributes.content,
                        textIsSafe: true,
                        okBtn: 'dialog.button_accept',
                    },
                });
            })
            .then((dialogRef) => {
                if (!dialogRef) {
                    return false;
                }
                return lastValueFrom(dialogRef.afterClose());
            })
            .then((consentIsGiven) => {
                if (consentIsGiven) {
                    this.isChecked = true;
                    this.onChecked();
                    this.cd.markForCheck();
                }
            });
    }

    onChecked(): void {
        if (!this.isChecked) {
            this.control.setValue(undefined);
            return;
        }

        lastValueFrom(this.formService.getConsent(this.identifier)).then((consent) => {
            if (!consent) {
                return;
            }

            const value: IConsentDto = {
                permissionId: consent.id,
            };

            this.control.setValue(value);
        });
    }
}

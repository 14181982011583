export type LazyContentData<T> = T & {
    alias?: LazyContentAliases;
    [x: string]: any;
};

export interface LazyContentGenericComponent<T = unknown> {
    data?: LazyContentData<T>;
    [x: string]: any;
}

export enum LazyContentAliases {
    MenuSpot = 'menuSpot',
    PreviewOverlay = 'previewOverlay',
    ActionBar = 'actionBar',
    Favorites = 'favorites',
    FindLeasingCalculator = 'findLeasingCalculator',
    ServiceBookingServiceDialog = 'serviceDialog',
    AutoProff = 'autoProff',
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { setTabState } from '../../../+state/configurator/configurator.actions';
import { ConfiguratorFacade } from '../../../+state/configurator/configurator.facade';
import { CardComponent } from '../../../card/card.component';
import { listAnimation } from '../../../utils/animations/list.animations';
import { CurrencyFormatterPipe } from '../../../utils/currency.pipe';
import { DialogService } from '../../../utils/dialog/dialog.service';
import { AddCurrencyPipe } from '../../utils/add-currency.pipe';
import { DataByIdPipe } from '../../utils/data-by-id.pipe';
import { GetPriceDifferencePipe } from '../../utils/get-price-difference.pipe';
import { ListItemDirective } from '../../../list-item/list-item.directive';
import { ConfiguratorTabStates } from '../../configurator';

@Component({
    selector: 'ncg-configurator-powertrain',
    animations: [listAnimation],
    template: `
        <ng-container *ngIf="configuratorFacade.routerQueryParams$ | async as params">
            <div class="container" *ngIf="configuratorFacade.trim$ | async as trim" [@listAnimation]="trim.variants?.length">
                <div class="configurator__cards">
                    <ng-container *ngFor="let variant of trim.variants">
                        <ncg-card
                            class="configurator__card"
                            contentClass="configurator__card-content"
                            [selected]="variant.powerTrainId === params.powertrain"
                            *ngIf="variant.powerTrainId && (configuratorFacade.model$ | async)?.powerTrains?.[variant.powerTrainId] as powertrain"
                        >
                            <h2 class="configurator__label card-label" *ngIf="configuratorFacade.tabStates$ | async as tabStates">
                                <button
                                    class="configurator__font-weight--emphasis powertrain-button configurator__link-cover"
                                    (click)="handlePowertrainChange(tabStates, powertrain.id)"
                                >
                                    {{ powertrain.fields | dataById: 'PowerTrainMarketingTitle' }}
                                </button>
                            </h2>
                            <h3 class="configurator__sub-headline">{{ 'configurator.specifications_title' | translate }}</h3>
                            <ul class="configurator__list" *ngIf="variant.fields as fields">
                                <!--PowerTrainMarketingDescription-->
                                <li ncgListItem *ngIf="powertrain.fields | dataById: 'PowerTrainMarketingDescription' as marketingDescription">
                                    {{ marketingDescription }}
                                </li>

                                <!--"Rækkevidde op til " + VariantElectricRange + " " + VariantElectricRangeUnit-->
                                <ng-container *ngIf="fields | dataById: 'VariantElectricRange' as range">
                                    <li ncgListItem *ngIf="fields | dataById: 'VariantElectricRangeUnit' as rangeUnit">
                                        {{ 'configurator.powertrain_electric_range' | translate: { range: range + ' ' + rangeUnit } }}
                                    </li>
                                </ng-container>

                                <!--PowerTrainTransmissionType-->
                                <li ncgListItem *ngIf="powertrain.fields | dataById: 'PowerTrainTransmissionType' as transmission">
                                    {{ transmission }}
                                </li>

                                <!--PowerTrainHorsePower + " " + PowerTrainHorsePowerUnit-->
                                <ng-container *ngIf="powertrain.fields | dataById: 'PowerTrainHorsePower' as horserpower">
                                    <li ncgListItem *ngIf="powertrain.fields | dataById: 'PowerTrainHorsePowerUnit' as horserpowerUnit">
                                        {{ horserpower }} {{ horserpowerUnit }}
                                    </li>
                                </ng-container>

                                <!--PowerTrainDrivenWheels-->
                                <li ncgListItem *ngIf="powertrain.fields | dataById: 'PowerTrainDrivenWheels' as wheels">
                                    {{ wheels }}
                                </li>

                                <!--"Forbrug: " + VariantFuelConsumption + " " + FuelConsumptionUnit-->
                                <ng-container *ngIf="fields | dataById: 'VariantFuelConsumption' as fuelConsumption">
                                    <li ncgListItem *ngIf="fields | dataById: 'VariantFuelConsumptionUnit' as fuelConsumptionUnit">
                                        {{
                                            'configurator.variant_fuel_consumption'
                                                | translate: { consumption: fuelConsumption + ' ' + fuelConsumptionUnit }
                                        }}
                                    </li>
                                </ng-container>

                                <!--"CO2: " +  VariantCo2Emission + " " + VariantCo2EmissionUnit-->
                                <ng-container *ngIf="fields | dataById: 'VariantCo2Emission' as co2">
                                    <li ncgListItem *ngIf="fields | dataById: 'VariantCo2EmissionUnit' as co2Unit">
                                        {{ 'configurator.variant_co2_emission' | translate: { emission: co2 + ' ' + co2Unit } }}
                                    </li>
                                </ng-container>

                                <!--"CO2-afgift " + VariantAnnualOwnershipTax + " kr./år"-->
                                <li
                                    ncgListItem
                                    *ngIf="fields | dataById: ('VariantAnnualOwnershipTax' | addCurrency | async) | currency as yearlyTax"
                                >
                                    {{ 'configurator.powertrain_yearly_tax' | translate: { tax: yearlyTax } }}
                                </li>
                            </ul>
                            <div class="configurator__price price">
                                {{
                                    trim.variants || []
                                        | getPriceDifference
                                            : ('VariantRetailSellingPrice' | addCurrency | async)
                                            : (variant.fields | dataById: ('VariantRetailSellingPrice' | addCurrency | async))
                                        | currency
                                }}
                            </div>
                        </ncg-card>
                    </ng-container>
                </div>
                <div class="configurator__legal">
                    <p *ngFor="let variant of trim.variants">
                        <strong>{{ variant.fields | dataById: 'VariantMarketingTitle' }}.</strong>
                        {{ variant.fields | dataById: 'VariantLegalText' }}<br />
                        {{ variant.fields | dataById: 'VariantLegalTextConfigurator' }}
                    </p>
                </div>
            </div>
        </ng-container>
    `,
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .card-label {
                margin: 0.5rem 0 0;
            }
            .price {
                width: max-content;
                padding-top: 0.8rem;
                margin-left: auto;
                margin-top: auto;
            }
            .powertrain-button {
                font-size: inherit;
                font-family: inherit;
                background: transparent;
                cursor: pointer;
                text-align: left;
            }
        `,
    ],
    imports: [
        CommonModule,
        CardComponent,
        RouterModule,
        TranslateModule,
        DataByIdPipe,
        CurrencyFormatterPipe,
        AddCurrencyPipe,
        GetPriceDifferencePipe,
        ListItemDirective,
    ],
})
export class ConfiguratorPowertrainComponent implements OnDestroy {
    private readonly unsubscribe = new Subject<void>();
    public readonly setTabState = setTabState;

    constructor(
        private readonly router: Router,
        private readonly dialogService: DialogService,
        public readonly configuratorFacade: ConfiguratorFacade
    ) {}

    public handlePowertrainChange(tabStates: ConfiguratorTabStates, id?: string | null): void {
        const doPowertrainChange = () => {
            this.router.navigate([], { queryParams: { powertrain: id }, queryParamsHandling: 'merge', replaceUrl: true });
            this.configuratorFacade.dispatch(setTabState({ tab: 'powertrain', tabState: 'visited' }));
        };

        const isStateDirty = Object.entries(tabStates)
            .filter(([tab]) => !['trim', 'powertrain'].includes(tab))
            .some(([, state]) => state !== 'pristine');

        if (!isStateDirty) {
            doPowertrainChange();
            return;
        }

        this.dialogService
            .openDialog({
                data: {
                    header: 'configurator.tab_state_dialog_heading',
                    text: 'configurator.tab_state_dialog_body',
                    okBtn: 'configurator.tab_state_dialog_ok_button',
                    closeBtn: 'configurator.tab_state_dialog_close_button',
                },
            })
            .then((dialogRef) => {
                if (!dialogRef) {
                    return;
                }
                dialogRef
                    .afterClose()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            doPowertrainChange();
                        }
                    });
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

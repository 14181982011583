import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SpecificationsSpot, SpecificationsGroup } from '@ncg/data';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

import { SpotBaseDirective } from '../spot-base.class';

@Component({
    selector: 'ncg-specifications-spot',
    template: `
        <div class="specifications-spot" *ngIf="data && data.items && data.items.length" ncgLoadIn>
            <div class="columns is-centered">
                <div class="column" [class.is-8]="!allowNarrowContent">
                    <div class="field specifications-spot__trims">
                        <div class="specifications-spot__trims_title">{{ 'trim_specifications.table_header_choose_trim' | translate }}</div>
                        <div class="select specifications-spot__trims_select">
                            <select class="specifications-spot__trims_input" [(ngModel)]="selectedGroup">
                                <option [ngValue]="variant" *ngFor="let variant of data.items">
                                    {{ variant.title }}{{ variant.transmissionType }}{{ variant.engineDrive }}
                                    {{ variant.priceDiscount ? ('trim_specifications.table_header_(campaign)' | translate) : '' }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="specifications-spot__selected-item" *ngIf="selectedGroup">
                        <div class="specifications-spot__price">
                            <h4 class="specifications-spot__price_title">{{ 'trim_specifications.table_header_price' | translate }}</h4>
                            <div class="specifications-spot__price_table">
                                <table class="table is-bordered">
                                    <tr>
                                        <th class="table__bg--light">{{ 'trim_specifications.table_header_choose_price_with_vat' | translate }}</th>
                                        <td
                                            *ngIf="selectedGroup.priceDiscount"
                                            [ngClass]="{ 'discount-price': selectedGroup.priceDiscount && selectedGroup.priceTotal }"
                                        >
                                            <span *ngIf="selectedGroup.priceTotal" class="line-through">{{ selectedGroup.priceTotal }}</span>
                                            <br *ngIf="selectedGroup.priceTotal" />
                                            <span class="bold">{{ selectedGroup.priceDiscount }}</span>
                                        </td>
                                        <td *ngIf="!selectedGroup.priceDiscount && selectedGroup.priceTotal">
                                            <span>{{ selectedGroup.priceTotal }}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="selectedGroup.priceYearly">
                                        <th class="table__bg--light">{{ 'trim_specifications.table_header_yearly' | translate }}</th>
                                        <td>{{ selectedGroup.priceYearly }}</td>
                                    </tr>
                                    <tr *ngIf="selectedGroup.energyLabels && selectedGroup.energyLabels.length">
                                        <th class="energylabel table__bg--light">
                                            {{ 'trim_specifications.table_header_energy_label' | translate }}
                                        </th>
                                        <td>
                                            <ncg-energy-label
                                                [energyLabels]="selectedGroup.energyLabels"
                                                imageClassModifier="energy-image--large"
                                            ></ncg-energy-label>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="accordion-spot specifications-spot__items">
                            <ncg-expansion-panel class="accordion-spot__item" *ngFor="let item of selectedGroup.values">
                                <div class="accordion-spot__title" expansion-title>{{ item.label }}</div>
                                <ng-container expansion-icon>
                                    <svg-icon-sprite
                                        src="chevron-light-down"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        class="is-flex"
                                    ></svg-icon-sprite>
                                </ng-container>
                                <div class="accordion-spot__text">
                                    <div class="columns specifications-spot__items_table" *ngFor="let property of item.values">
                                        <div class="specifications-spot__property-label column is-3">{{ property.label }}</div>
                                        <div class="specifications-spot__property-value column is-9" [ngSwitch]="property.code">
                                            <ng-container *ngSwitchCase="'vehicle_ncap'">
                                                <ncg-ncap-stars [stars]="property.value"></ncg-ncap-stars>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{ property.value }}
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ncg-expansion-panel>
                        </div>
                    </div>
                </div>
            </div>
            <div class="specifications-spot__button" *ngIf="data.link && data.link.url">
                <ng-container *ngIf="data.link.isExternal; else internalLink">
                    <a class="button is-primary" rel="noopener" [href]="data.link.url" [target]="data.link.target">
                        {{ data.link.name }}
                    </a>
                </ng-container>
                <ng-template #internalLink>
                    <a class="button is-primary" [routerLink]="data.link.url">{{ data.link.name }}</a>
                </ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificationsComponent extends SpotBaseDirective implements OnInit {
    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly translate: TranslateService
    ) {
        super();
    }

    static ref = 'specifications';

    @Input() data: SpecificationsSpot;

    selectedGroup?: SpecificationsGroup;

    ngOnInit() {
        this.selectedGroup = this.data.items[0];
        firstValueFrom(this.translate.get('trim_specifications')).then((translationData: any) => {
            this.data.items.forEach((item) => {
                if (item.transmissionType) {
                    if (item.transmissionType === 'automatic') {
                        item.transmissionType = ` ${translationData.title_transmission}`;
                    } else if (item.transmissionType === 'manual') {
                        item.transmissionType = '';
                    }
                }

                if (item.engineDrive) {
                    if (item.engineDrive.toLowerCase().trim() === '4wd') {
                        item.engineDrive = ` ${translationData.title_drive}`;
                    } else {
                        item.engineDrive = '';
                    }
                }
            });
            this.cd.markForCheck();
        });
    }
}

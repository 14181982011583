import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICarouselItem } from '@ncg/data';
import { HdAspect } from '../../utils/helpers/aspect-ratio';

@Component({
    selector: 'ncg-carousel-item',
    template: `
        <picture class="is-ratio-hd" data-chromatic="ignore">
            <source media="(min-width: 1024px)" [srcset]="item.image | imageUrl: { width: 1550, heightratio: hdAspect, mode: 'crop' } : 2" />
            <source media="(min-width: 768px)" [srcset]="item.image | imageUrl: { width: 1024, heightratio: hdAspect, mode: 'crop' } : 2" />
            <source media="(min-width: 415px)" [srcset]="item.image | imageUrl: { width: 767, heightratio: hdAspect, mode: 'crop' } : 2" />
            <source media="(max-width: 414px)" [srcset]="item.image | imageUrl: { width: 394, heightratio: hdAspect, mode: 'crop' } : 2" />
            <img
                [src]="item.image | imageUrl: { width: 1550, heightratio: hdAspect, mode: 'crop' }"
                [alt]="item.image.altText || ''"
                loading="lazy"
                sizes="100vw"
                ncgImageLoad
            />
        </picture>
        <div
            *ngIf="item.carouselTitle || item.carouselSubtitle"
            class="carousel-item__header"
            [ngClass]="{
                'is-bottom': item.textAlignment === 'Bottom',
                'is-top': item.textAlignment === 'Top',
                'has-text-color-light': item.color === 'Light',
                'has-text-color-dark': item.color === 'Dark'
            }"
        >
            <ncg-spot-headline *ngIf="item.carouselTitle" level="3" [headlineClass]="'carousel-item__title'">
                <span
                    [ngClass]="{
                        'has-text-color-light': item.color === 'Light',
                        'has-text-color-dark': item.color === 'Dark'
                    }"
                    >{{ item.carouselTitle }}</span
                >
            </ncg-spot-headline>

            <p
                *ngIf="item.carouselSubtitle"
                [ngClass]="{
                    'has-text-color-light': item.color === 'Light',
                    'has-text-color-dark': item.color === 'Dark',
                    'mt-5': !item.carouselTitle
                }"
                [innerHTML]="item.carouselSubtitle"
            ></p>

            <ncg-button
                *ngIf="item.link"
                class="carousel-item__button is-block mt-5"
                [buttonClass]="item.color === 'Dark' ? 'is-outline-black' : 'is-outline-white'"
                [link]="item.link"
                [linkHoverTitle]="item.linkHoverTitle"
            ></ncg-button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselSpotItemComponent {
    @Input() item: ICarouselItem;
    hdAspect: number = HdAspect;
}

import { Injectable } from '@angular/core';
import { Observable, distinctUntilChanged, filter, fromEvent, map, of, pairwise, shareReplay, throttleTime } from 'rxjs';

import { FeatureDetectionService } from './feature-detection.service';

@Injectable({
    providedIn: 'root',
})
export class ScrollStatusService {
    private readonly defaultThrottleTime = 50;
    public scrollEvent$ = this.getScroll();
    public scrollPosition$ = this.getScrollPosition().pipe(shareReplay({ bufferSize: 1, refCount: false }));
    public isScrollingDown$ = this.getScrollingDown().pipe(shareReplay({ bufferSize: 1, refCount: false }));
    public scrollFraction$ = this.getScrollFraction().pipe(shareReplay({ bufferSize: 1, refCount: false }));

    constructor(private readonly featureDetection: FeatureDetectionService) {}

    private getScroll(): Observable<Event> {
        if (this.featureDetection.isServer()) {
            return of();
        }
        return fromEvent(document, 'scroll');
    }

    // Returns current scrollPosition
    public getScrollPosition(throttleDuration?: number): Observable<number> {
        if (this.featureDetection.isServer()) {
            return of<number>(0);
        }
        return this.scrollEvent$ // Return the same observable to subscribers
            .pipe(
                throttleTime(throttleDuration ?? this.defaultThrottleTime), // Throttle scroll
                map(() => (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop),
                distinctUntilChanged() // Only publish if value changes
            );
    }

    // Returns true if scrolling down
    public getScrollingDown(topOffset = 0): Observable<boolean> {
        if (this.featureDetection.isServer()) {
            return of<boolean>(false);
        }
        return this.scrollEvent$ // Return the same observable to subscribers
            .pipe(
                throttleTime(this.defaultThrottleTime), // Throttle scroll
                map(() => window.pageYOffset),
                filter((scrollValue) => scrollValue >= topOffset),
                pairwise(), // Get last and new value as array: [last, new]
                map(([y1, y2]): boolean => Boolean(y1 < y2)),
                distinctUntilChanged() // Only publish if value changes
            );
    }

    // Returns current fraction of scroll
    public getScrollFraction(): Observable<number> {
        if (this.featureDetection.isServer()) {
            return of<number>(0);
        }
        return this.scrollPosition$.pipe(
            map((scrollPos) => Math.round((scrollPos / window.innerHeight) * 10) / 10), // round to 1 decimal
            distinctUntilChanged() // Only publish if value changes
        );
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INavigationResponse } from '@ncg/data';
import { SettingsService } from '../../core/settings.service';

@Component({
    selector: 'ncg-mega-menu',
    template: `
        <div *ngIf="item?.children?.length" class="mega-menu__container mega-menu__container--animate">
            <div class="container" *ngIf="settingsService.get() | async as settings">
                <div class="columns is-gapless" *ngIf="settings.shortcuts.length > 0; else noShortcuts">
                    <div class="column is-9">
                        <div class="columns is-gapless is-multiline">
                            <ng-container *ngTemplateOutlet="megaMenu; context: { hasShortcuts: true }"></ng-container>
                        </div>
                    </div>
                    <div class="column">
                        <ncg-shortcuts></ncg-shortcuts>
                    </div>
                </div>
                <ng-template #noShortcuts>
                    <div class="columns is-gapless is-multiline">
                        <ng-container *ngTemplateOutlet="megaMenu; context: { hasShortcuts: false }"></ng-container>
                    </div>
                </ng-template>
            </div>
        </div>

        <ng-template #megaMenu let-hasShortcuts="hasShortcuts">
            <ng-container *ngFor="let child of item?.children; trackBy: trackByMethod">
                <div
                    *ngIf="child?.includeInNavigation && !child.hideInMenu"
                    class="column mega-menu__item mega-menu__item--animate"
                    [ngClass]="{
                        'is-one-quarter': hasShortcuts,
                        'is-one-fifth': !hasShortcuts
                    }"
                >
                    <ng-container *ngIf="child?.pageLink?.isExternal; else internalLink">
                        <a class="mega-menu__link animate-mask__link" rel="noopener" [href]="child?.pageLink?.url" [target]="child?.pageLink?.target">
                            <ng-container *ngTemplateOutlet="menuLinkTmpl; context: { child: child }"></ng-container>
                        </a>
                    </ng-container>
                    <ng-template #internalLink>
                        <a class="mega-menu__link animate-mask__link" [routerLink]="[child?.pageLink?.url ? child?.pageLink?.url : child.url]">
                            <ng-container *ngTemplateOutlet="menuLinkTmpl; context: { child: child }"></ng-container>
                        </a>
                    </ng-template>
                </div>
            </ng-container>
        </ng-template>

        <ng-template #menuLinkTmpl let-child="child">
            <ncg-mega-menu-image class="mega-menu__link--image" *ngIf="child?.image" [image]="child?.image"></ncg-mega-menu-image>
            <div>
                <div class="mega-menu__link--title" *ngIf="child.name">{{ child.name }}</div>
                <div class="mega-menu__link--description" *ngIf="!child.hideReferenceDescriptionInMainMenu && child.description">
                    {{ child.description }}
                </div>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaMenuComponent {
    @Input() item?: INavigationResponse;

    constructor(public readonly settingsService: SettingsService) {}

    trackByMethod(index: number, item: any) {
        return item.id || index;
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ExtendedHeroSpot, IImage } from '@ncg/data';
import { CinemaAspect, MobileAspectRatio } from '../../utils/helpers/aspect-ratio';
import { ImageUrl } from '../../utils/helpers/image-helper';
import { SpotBaseDirective } from '../spot-base.class';
import { breakpoints, breakpointQueries } from '../../utils/helpers/breakpoints';

@Component({
    selector: 'ncg-extended-hero-spot',
    template: `
        <div class="extended-hero-spot">
            <div class="extended-hero-spot__container">
                <ncg-link *ngIf="data.links && data.links.length === 1" [link]="data.links[0]" styleClass="extended-hero-spot__link"></ncg-link>
                <ncg-video
                    class="extended-hero-spot__media-container"
                    [ngClass]="{ 'extended-hero-spot__media-container--media-filter': data.mediaFilter }"
                    *ngIf="data.video && data.video?.url; else imageTmpl"
                    [video]="data.video"
                    [poster]="data.image"
                    [preload]="isFirstSpot ? 'auto' : 'metadata'"
                ></ncg-video>
                <ng-template #imageTmpl>
                    <picture
                        *ngIf="data.image as image"
                        class="extended-hero-spot__media-container"
                        [ngClass]="{ 'extended-hero-spot__media-container--media-filter': data.mediaFilter }"
                    >
                        <source [srcset]="mobileSrcset" [media]="breakpointQueries.mobile" />
                        <source [srcset]="mobileSrcset" [media]="breakpointQueries.mobileLarge" />
                        <source [srcset]="mobileSrcset" [media]="breakpointQueries.tablet" />
                        <source [srcset]="desktopSrcset" [media]="breakpointQueries.desktop" />

                        <ng-container *ngIf="isFirstSpot; else defaultImageTmpl">
                            <img [src]="image | imageUrl: { width: 1440, mode: 'crop' }" [alt]="image.altText || data.title || ''" sizes="97vw" />
                        </ng-container>
                        <ng-template #defaultImageTmpl>
                            <img
                                [src]="image | imageUrl: { width: 1440, mode: 'crop' }"
                                [alt]="image.altText || data.title || ''"
                                sizes="97vw"
                                loading="lazy"
                                ncgImageLoad
                            />
                        </ng-template>
                    </picture>
                </ng-template>
                <div class="extended-hero-spot__content" ncgLoadIn *ngIf="data.title || data.subtitle || data.price || data.links">
                    <div>
                        <ng-container *ngIf="data.useAsHeader; else noHeader">
                            <h1
                                *ngIf="data.title"
                                class="extended-hero-spot__title"
                                [innerHTML]="data.title | usedCarsTags | async | safe: 'html'"
                            ></h1>
                            <h2
                                *ngIf="data.subtitle"
                                class="extended-hero-spot__subtitle"
                                ncgInterceptLinks
                                [innerHTML]="data.subtitle | usedCarsTags | async | safe: 'html'"
                            ></h2>
                        </ng-container>
                        <ng-template #noHeader>
                            <h2
                                *ngIf="data.title"
                                class="extended-hero-spot__title"
                                [innerHTML]="data.title | usedCarsTags | async | safe: 'html'"
                            ></h2>
                            <div
                                *ngIf="data.subtitle"
                                class="extended-hero-spot__subtitle"
                                ncgInterceptLinks
                                [innerHTML]="data.subtitle | usedCarsTags | async | safe: 'html'"
                            ></div>
                        </ng-template>
                        <p *ngIf="data.price" class="extended-hero-spot__price">
                            {{ 'models.pricefrom' | translate: { price: data.price } }}
                        </p>
                    </div>
                    <div class="extended-hero-spot__cta" *ngIf="data.links">
                        <ncg-button
                            *ngFor="let link of data.links; let first = first"
                            [link]="link"
                            [buttonClass]="first ? 'is-extended-hero' : 'is-extended-hero-secondary'"
                        ></ncg-button>
                    </div>
                </div>
                <ng-container *ngIf="data.energyLabels || data.legalCarText">
                    <div class="extended-hero-spot__overlay"></div>
                    <div class="extended-hero-spot__legal" ncgLoadIn>
                        <ncg-legal [legalText]="data.legalCarText" [energyLabels]="data.energyLabels"></ncg-legal>
                    </div>
                </ng-container>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedHeroSpotComponent extends SpotBaseDirective implements OnInit {
    static ref = 'extendedhero';
    @Input() data: ExtendedHeroSpot;
    public desktopAspectRatio: number = CinemaAspect;
    public mobileAspectRatio: number = MobileAspectRatio;
    public mobileSrcset: string;
    public desktopSrcset: string;
    public srcset: string;
    public breakpointQueries = breakpointQueries;

    constructor(private readonly cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (this.data.image) {
            this.generateSrcset(this.data.image);
        }
    }

    private generateSrcset(image: IImage | undefined): void {
        if (!image || !image.url) {
            return;
        }

        const mobileSrcsetArray = new Set();
        const desktopSrcsetArray = new Set();

        for (const width of Object.values(breakpoints)) {
            const imageOptions: Parameters<typeof ImageUrl>[1] = {
                mode: 'crop',
            };

            // if original image width is known, only try to add images that are smaller than the image width to avoid upscaling.
            const maxWidth = image.width || 1440;
            const strWidth = width <= maxWidth ? width : maxWidth;
            const strRetinaWidth = width * 2 <= maxWidth ? width * 2 : maxWidth;

            if (width < breakpoints.tablet) {
                imageOptions.heightratio = this.mobileAspectRatio;
                const str = `${ImageUrl(image, { ...imageOptions, width: strWidth })} ${width}w`;
                const retinaStr = `${ImageUrl(image, { ...imageOptions, width: strRetinaWidth })} ${width * 2}w`;
                mobileSrcsetArray.add(str);
                mobileSrcsetArray.add(retinaStr);
            } else {
                imageOptions.heightratio = this.desktopAspectRatio;
                const str = `${ImageUrl(image, { ...imageOptions, width: strWidth })} ${width}w`;
                const retinaStr = `${ImageUrl(image, { ...imageOptions, width: strRetinaWidth })} ${width * 2}w`;
                desktopSrcsetArray.add(str);
                desktopSrcsetArray.add(retinaStr);
            }
        }

        this.mobileSrcset = Array.from(mobileSrcsetArray).join(', ');
        this.desktopSrcset = Array.from(desktopSrcsetArray).join(', ');
        this.cd.markForCheck();
    }
}

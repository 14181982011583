import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getBuildIdQuery, NcgLocation, NcgLocationFormCategory } from '@ncg/data';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { FeatureDetectionService } from '../core/feature-detection.service';
import { randomId } from '../core/helpers';
import { LocationService } from '../location/location.service';
import { SpotsConfig } from '../spots/spots-config';
import { FormService } from './form.service';

export const LOCATION_QUERY_SELECTOR = 'selectedlocationid';
export const LOCATION_QUERY_CATEGORY = 'selectedCategory';

@Component({
    selector: 'ncg-location-select',
    template: `
        <div class="select-location custom-select" *ngIf="parentForm">
            <div class="field" *ngIf="showDepartments">
                <label [for]="departmentId" class="label">{{ 'forms.choose_department_label' | translate }}</label>
                <div class="select is-full">
                    <select [id]="departmentId" name="department" [(ngModel)]="category" (change)="onDepartmentChange()">
                        <option [ngValue]="'retail'">{{ 'forms.department_retail_label' | translate }}</option>
                        <option [ngValue]="'workshop'">{{ 'forms.department_workshop_label' | translate }}</option>
                    </select>
                </div>
                <p class="help" *ngIf="helpTextDepartment">{{ helpTextDepartment }}</p>
            </div>
            <div class="field" [formGroup]="parentForm" *ngIf="showLocations">
                <label [for]="locationId" class="label" *ngIf="showLabel">
                    <span>{{ locationLabel }}*</span>
                </label>
                <div class="control has-icons-right">
                    <input
                        data-testid="locationSelect"
                        type="text"
                        class="input"
                        name="location"
                        [id]="locationId"
                        [attr.placeholder]="showLabel ? '' : locationLabel"
                        [class.is-danger]="errors(controlName)"
                        [formControlName]="controlName"
                        [autocomplete]="'off'"
                        [ngbTypeahead]="search"
                        [focusFirst]="true"
                        [resultTemplate]="locationListItemTmpl"
                        [inputFormatter]="formatter"
                        (click)="updateNext($event)"
                        (focus)="updateNext($event)"
                        (blur)="onBlur()"
                        (selectItem)="onSelectedItem($event)"
                        #locationTypeahead="ngbTypeahead"
                    />
                    <span class="icon is-right" [ngClass]="{ 'is-clickable': locationTypeahead.isPopupOpen() }" aria-hidden="true">
                        <span class="chevron chevron--animate" [ngClass]="{ 'chevron--up': locationTypeahead.isPopupOpen() }">
                            <img [src]="'assets/icons/select-arrow.svg' + buildIdQuery" />
                        </span>
                    </span>
                    <p class="help" *ngIf="helpTextLocation">{{ helpTextLocation }}</p>
                    <p class="help is-danger" *ngIf="location?.errors && location?.touched">
                        <ng-container *ngIf="location?.hasError('invalidLocation'); else otherErrorsTmpl">
                            {{ 'forms.error_invalid_location' | translate }}
                        </ng-container>
                        <ng-template #otherErrorsTmpl>{{ 'forms.error_required_field' | translate }}</ng-template>
                    </p>
                </div>
                <ng-template #locationListItemTmpl let-location="result" let-searchTerm="term">
                    <div class="select-location-item" *ngIf="location">
                        <div class="select-location-item__title">
                            <ngb-highlight [result]="location.name" [term]="searchTerm"></ngb-highlight>
                        </div>
                        <address class="select-location-item__address" [ngClass]="{ 'pb-2': isBrandlayout }" *ngIf="location.address">
                            <span *ngIf="location?.address?.street">
                                <ngb-highlight [result]="location?.address?.street" [term]="searchTerm"></ngb-highlight> –
                            </span>
                            <span *ngIf="location?.address?.zipcode">
                                <ngb-highlight [result]="location?.address?.zipcode" [term]="searchTerm"></ngb-highlight
                            ></span>
                            <span *ngIf="location?.address?.city">
                                <ngb-highlight [result]="' ' + location?.address?.city" [term]="searchTerm"></ngb-highlight>
                            </span>
                        </address>
                    </div>
                    <div class="select-location-brand columns is-variable is-2 is-vcentered is-mobile is-multiline" *ngIf="isBrandlayout">
                        <div class="column is-narrow py-2" *ngFor="let brand of location.brands">
                            <ncg-logos [key]="brand" iconWidthSm="18" iconWidth="21" iconWidthLg="30" iconWidthXl="35"></ncg-logos>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSelectComponent implements OnInit, OnDestroy {
    @ViewChild('locationTypeahead') locationTypeahead: NgbTypeahead;
    @Output() isLocationsFound: EventEmitter<boolean> = new EventEmitter();
    @Output() categoryChange: EventEmitter<NcgLocationFormCategory> = new EventEmitter();
    @Input() parentForm: UntypedFormGroup;
    @Input() controlName = 'location';
    @Input() isTouched?: boolean; // Used to trigger changeDetection. When user submits an invalid form
    @Input() showDepartments = false;
    @Input() isDepartmentsAllowed = false;
    @Input() category: NcgLocationFormCategory;
    @Input() allowedCategory?: NcgLocationFormCategory;
    @Input() helpTextDepartment?: string;
    @Input() helpTextLocation?: string;
    @Input() showLabel = true;
    @Input() locationWhitelist?: string[];
    @Input() locationBlacklist?: string[];
    @Input() allowShowrooms = false;
    @Input() allowTestdriveonly = false;
    @Input() applyFilter: boolean = false;
    showLocations = true;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    locations: {
        retail: NcgLocation[] | [];
        workshop: NcgLocation[] | [];
    } = { retail: [], workshop: [] };
    currentLocations: NcgLocation[] = [];
    translation?: any;
    locationLabel = '';
    isBrandlayout = false;
    hasLocationsInBoth: boolean;
    departmentId: string;
    locationId: string;
    buildIdQuery = getBuildIdQuery();

    constructor(
        private readonly locationService: LocationService,
        private readonly featureDetectionService: FeatureDetectionService,
        private readonly cd: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly translate: TranslateService,
        @Inject(SpotsConfig) private readonly spotsConfig: SpotsConfig
    ) {}

    async ngOnInit() {
        this.departmentId = randomId('department');
        this.locationId = randomId('location');
        this.isBrandlayout = this.spotsConfig.isBrandLayout;
        firstValueFrom(this.translate.get('forms')).then((translationData: any) => {
            this.translation = translationData;
            this.changeLabel();
            this.cd.detectChanges();
        });

        if (this.featureDetectionService.isBrowser()) {
            await this.getLocations();
            if (this.isDepartmentsAllowed) {
                this.toggleDepartmentsDropdownVisibility();
            }
            this.toggleLocationDropdownVisibility();
            this.getLocationFromQuery();
        }
    }

    async getLocations() {
        const category = this.allowedCategory ?? '';
        const locationsRequests = [this.locationService.getLocationsBySiteContext(category)];
        if (this.allowShowrooms && category === 'retail') {
            locationsRequests.push(this.locationService.getLocationsBySiteContext('showroom'));
        }
        if (this.allowTestdriveonly && category === 'retail') {
            locationsRequests.push(this.locationService.getLocationsBySiteContext('testdriveonly'));
        }

        const data = await firstValueFrom(
            combineLatest(locationsRequests).pipe(
                map((locationsArray) =>
                    locationsArray.reduce((prev, curr) => ({
                        total: prev.total + curr.total,
                        aggregations: {
                            categories: { ...prev.aggregations.categories, ...curr.aggregations.categories },
                            locations: [...prev.aggregations.locations, ...curr.aggregations.locations],
                        },
                    }))
                )
            )
        );

        let filteredLocations = this._filterLocationsByBlackWhiteList(data?.aggregations.locations ?? []);
        // filter applied here to remove non-bookable locations
        filteredLocations = this.filterLocations(filteredLocations);

        this.locations = {
            retail: filteredLocations.filter((item) => {
                const includeShowroom = this.allowShowrooms && item.category.code === 'showroom';
                const includeTestdriveonly = this.allowTestdriveonly && item.category.code === 'testdriveonly';
                if (item.category.code === 'retail' || includeShowroom || includeTestdriveonly) {
                    return item;
                }
            }),
            workshop: filteredLocations.filter((item) => item.category.code === 'workshop'),
        };

        // NIC-1583: Hack to allow showrooms to be treated as retail locations.
        const findCategory = filteredLocations.length === 1 ? filteredLocations[0].category.code : this.category;
        // This is making me ill. Using [].includes doesnt satiesfy TS, so this is it...
        this.category = findCategory === 'showroom' || findCategory === 'testdriveonly' || findCategory === '' ? 'retail' : findCategory;

        this.currentLocations = this.locations[this.category];
    }

    /**
     * Only show department dropdown, if there are locations in both
     */
    toggleDepartmentsDropdownVisibility() {
        this.hasLocationsInBoth = this.locations.retail.length > 0 && this.locations.workshop.length > 0;
        this.showDepartments = this.hasLocationsInBoth;
        this.cd.detectChanges();
    }

    /**
     * Only show location if there are more than one allowed location
     */
    toggleLocationDropdownVisibility() {
        const locationsInCategory = this.locations[this.category];

        if (!this.hasLocationsInBoth && locationsInCategory?.length === 1) {
            this.showLocations = false;
            this.setLocationValue(locationsInCategory[0]);
        }
    }

    /**
     * @param locations
     * @description Filter locations by whitelist or blacklist.
     * If black and white list is empty just return argument
     */
    private _filterLocationsByBlackWhiteList(locations: NcgLocation[]): NcgLocation[] {
        if (!this.locationWhitelist && !this.locationBlacklist) {
            return locations;
        }

        const filterList = this.locationWhitelist || this.locationBlacklist || [];
        const shouldWhiteList = !!this.locationWhitelist?.length;
        const allowedList = locations.filter((location) => {
            const isLocationFound = filterList.includes(location.store_code);
            if ((shouldWhiteList && isLocationFound) || (!shouldWhiteList && !isLocationFound)) {
                return location;
            }
        });

        this.isLocationsFound.emit(!!allowedList.length);
        return allowedList;
    }

    changeLabel() {
        if (this.translation) {
            this.locationLabel = this.category === 'retail' ? this.translation.choose_dealership_label : this.translation.choose_workshop_label;
            this.cd.detectChanges();
        }
    }

    onDepartmentChange() {
        this.resetLocationsControl();
        this.changeLabel();
        this.currentLocations = this.locations[this.category];
        this.toggleLocationDropdownVisibility();
        this.categoryChange.emit(this.category);
    }

    ngOnDestroy() {
        this.focus$.complete();
        this.click$.complete();
    }

    getLocationFromQuery() {
        const id = this.route.snapshot.queryParamMap.get(LOCATION_QUERY_SELECTOR);
        if (id) {
            this.setSelectedLocationById(id);
        }
    }

    // Is actaully InputEvent, which have the value property
    updateNext(event: any) {
        this.click$.next(event.target.value);
    }

    setSelectedLocationById(id: string) {
        const locationFound = this.currentLocations.find((location) => location.store_code === id);

        if (locationFound) {
            this.setLocationValue(locationFound);
        }
    }

    setLocationValue(value: NcgLocation) {
        if (this.location) {
            this.location.setValue(value);
            this.location.markAsTouched();
        }
        this.cd.markForCheck();
    }

    // to filter out non-bookable locations by store_code: Bayern AutoGroup Horsens Værksted & Bayern AutoGroup Sønderborg Værksted, used in the getLocations() method
    filterLocations(locations: NcgLocation[]): NcgLocation[] {
        if (!this.applyFilter) {
            return locations;
        }
        // TODO hard coded store_code will eventually be removed when NIC updates the location categories to include service
        const excludedNames = ['BAG-Horsens-V', 'BAG-Sønderborg-V'];
        return locations.filter((location) => !excludedNames.includes(location.store_code));
    }
    search = (searchTerm$: Observable<string>) => {
        const debouncedText$ = searchTerm$.pipe(distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(
            filter(() => {
                this.clearSelectedLocation();
                return !this.locationTypeahead.isPopupOpen();
            })
        );

        return merge(debouncedText$, this.focus$, clicksWithClosedPopup$).pipe(
            map((term: string) => {
                const searchTerm = term.toLocaleLowerCase();
                return searchTerm === ''
                    ? this.currentLocations
                    : this.currentLocations.filter(
                          (location) =>
                              (location.name && location.name.toLocaleLowerCase().indexOf(searchTerm) > -1) ||
                              (location.address && location.address.city && location.address.city.toLocaleLowerCase().indexOf(searchTerm) > -1) ||
                              (location.address && location.address.street && location.address.street.toLocaleLowerCase().indexOf(searchTerm) > -1) ||
                              (location.address && location.address.zipcode && location.address.zipcode.toLocaleLowerCase().indexOf(searchTerm) > -1)
                      );
            })
        );
    };

    formatter = (location: NcgLocation) => {
        if (location && location.store_code && location.address) {
            return `${location.name} (${location.address.city || ''} | ${location.address.street || ''})`;
        }
        return '';
    };

    clearSelectedLocation() {
        if (this.location) {
            this.location.setValue(undefined);
            this.cd.markForCheck();
        }
    }

    onSelectedItem(event: NgbTypeaheadSelectItemEvent) {
        if (event && event.item) {
            this.location?.setValue(event.item);
        } else {
            this.location?.setValue(undefined);
        }
    }

    onBlur() {
        if (this.location && this.location.value && this.location.value.store_code) {
            return;
        }

        this.location?.setValue(undefined);
        this.location?.setErrors({ invalidLocation: true });
    }

    resetLocationsControl() {
        if (this.location) {
            this.location.patchValue('');
            this.location.markAsPristine();
            this.location.markAsUntouched();
        }
    }

    errorHandling() {
        this.isLocationsFound.emit(false);
    }

    get location() {
        return this.parentForm && this.parentForm.get(this.controlName) ? this.parentForm.get(this.controlName) : null;
    }

    errors = (controlName: string) => FormService.errors(controlName, this.parentForm);
}

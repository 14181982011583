import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { INavigationResponse } from '@ncg/data';
import { LazyContentAliases } from '../../../$lazy-content/models/lazy-content.models';
import { SettingsService } from '../../../core/settings.service';

import { SpotsConfig } from '../../../spots/spots-config';
import { ModelsAspectRatio } from '../../../utils/helpers/aspect-ratio';

@Component({
    selector: 'ncg-mobile-nav-list',
    template: `
        <ng-content></ng-content>

        <ng-container *ngIf="currentItem">
            <ng-container *ngIf="currentItem.pageLink && currentItem.template === 'linkPage'; else notLinkPageTmpl">
                <ng-container *ngIf="currentItem.pageLink?.isExternal; else internalLink">
                    <a class="mobile-menu__goto" rel="noopener" [href]="currentItem.pageLink?.url" [target]="currentItem.pageLink?.target">
                        {{ currentItem.name }}
                    </a>
                </ng-container>
                <ng-template #internalLink>
                    <a class="mobile-menu__goto" [routerLink]="currentItem.pageLink.url">{{ currentItem.name }}</a>
                </ng-template>
            </ng-container>
            <ng-template #notLinkPageTmpl>
                <a class="mobile-menu__goto" [routerLink]="currentItem.url">
                    <ng-container *ngIf="currentItem.nameOnMobile; else nameTmlp">{{ currentItem.nameOnMobile }}</ng-container>
                    <ng-template #nameTmlp>
                        <span *ngIf="spotsConfig.isMobileBackMultiLevel">{{ 'navigation.go_to' | translate }} </span>
                        <span>{{ currentItem.name }}</span>
                    </ng-template>
                </a>
            </ng-template>
        </ng-container>

        <ng-container *ngFor="let item of menuList; trackBy: trackByMethod">
            <ng-container *ngIf="item?.includeInNavigation && !item.hideInMenu">
                <ng-container
                    *ngIf="item.template === 'modelPage' || item.template === 'campaignPage'; then isModelPageTmpl; else isNotModelPageTmpl"
                ></ng-container>

                <ng-template #isModelPageTmpl>
                    <a class="mobile-menu__item mobile-menu__model is-flex" [routerLink]="item.url" routerLinkActive="active">
                        <picture *ngIf="item.image as image" class="mobile-model__image is-ratio-model">
                            <source [srcset]="image | imageUrl: { width: 116, heightratio: modelsAspectRatio, mode: 'crop' } : 2" />
                            <img
                                [src]="image | imageUrl: { width: 116, heightratio: modelsAspectRatio, mode: 'crop' }"
                                [alt]="item.image.altText || item.name"
                                loading="lazy"
                                ncgImageLoad
                            />
                        </picture>
                        <div class="mobile-model__header is-flex is-justify-content-center is-flex-direction-column">
                            <p class="mobile-model__title">{{ item.name }}</p>
                            <p class="mobile-model__text" *ngIf="item.priceFormatted">
                                {{ 'models.pricefrom' | translate: { price: item.priceFormatted } }}
                            </p>
                        </div>
                    </a>
                </ng-template>

                <ng-template #isNotModelPageTmpl>
                    <!-- Check if item has children or not -->
                    <ng-container
                        *ngIf="item.children?.length && !item.hideChildrenOnMobile; then itemHasChildrenTmpl; else itemHasNoChildrenTmpl"
                    ></ng-container>

                    <!-- item has children -->
                    <ng-template #itemHasChildrenTmpl>
                        <button class="mobile-menu__item level is-flex" (click)="onNavigateClicked(item)">
                            <span>{{ item.name }}</span>
                            <svg-icon-sprite
                                [src]="spotsConfig.iconType === 'light' ? 'chevron-light-right' : 'chevron-right'"
                                [viewBox]="'0 0 30 30'"
                                [width]="'30px'"
                                [height]="'30px'"
                                aria-hidden="true"
                                class="is-flex mobile-menu__icon"
                            ></svg-icon-sprite>
                        </button>
                    </ng-template>

                    <!-- item has no children -->
                    <ng-template #itemHasNoChildrenTmpl>
                        <ng-container *ngIf="item.pageLink && item.pageLink?.isExternal; else internalLink">
                            <a class="mobile-menu__item level is-flex" rel="noopener" [href]="item.pageLink.url" [target]="item.pageLink?.target">
                                {{ item.name }}
                            </a>
                        </ng-container>
                        <ng-template #internalLink>
                            <a
                                class="mobile-menu__item level is-flex"
                                [routerLink]="[item.pageLink ? item.pageLink.url : item.url]"
                                routerLinkActive="active"
                                >{{ item.name }}</a
                            >
                        </ng-template>
                    </ng-template>
                </ng-template>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="currentItem?.template === 'modelOverviewPage' && (settings.getMenuSpot() | async) as menuSpotData">
            <ng-template *ngIf="menuSpotData.enableMenuSpot" ncgLazyComponentLoader [data]="menuSpotData" [alias]="menuSpotKey"></ng-template>
        </ng-container>

        <ncg-meta-menu *ngIf="isContentLoaded && isTopLevelList" [isInMobile]="true"></ncg-meta-menu>
        <ncg-shortcuts *ngIf="isContentLoaded && !isTopLevelList" [isInMobile]="true"></ncg-shortcuts>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavListComponent implements AfterViewInit {
    @Input() isTopLevelList?: boolean;
    @Input() menuList?: INavigationResponse[];
    @Input() currentItem: INavigationResponse;
    @Input() currentPath?: string;
    @Output() navigateTo: EventEmitter<INavigationResponse> = new EventEmitter();
    isContentLoaded?: boolean;
    modelsAspectRatio = ModelsAspectRatio;
    menuSpotKey = LazyContentAliases.MenuSpot;
    constructor(
        @Inject(SpotsConfig) public spotsConfig: SpotsConfig,
        public readonly settings: SettingsService
    ) {}

    ngAfterViewInit() {
        this.isContentLoaded = true;
    }

    onNavigateClicked(item: INavigationResponse) {
        this.navigateTo.emit(item);
    }

    trackByMethod(index: number, item: any) {
        return item.id || index;
    }
}
